<template>
  <div class="mt-10">
    <a-row :span="24" :offset="0">
      <a-col :span="24">
        {{ reservation_policies }}
        <a-row
          v-for="(policy, index) in reservation_policies"
          :key="policy"
          class="policy"
          type="flex"
          justify="center"
          :gutter="[20, 0]"
        >
          <a-col :span="22">
            <a-form-item class="travel-input">
              <label
                :class="
                  form.getFieldValue(`reservation_policies[${index}]['title']`)
                    ? 'filled'
                    : ''
                "
                >Título da política</label
              >
              <a-input
                placeholder="Título da política"
                v-decorator="[
                  `reservation_policies[${index}]['title']`,
                  {
                    initialValue: policy.title,
                    validateTrigger: ['blur'],
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
              </a-input>
            </a-form-item>

            <a-form-item>
              <label
                :class="
                  form.getFieldValue(`reservation_policies[${index}]['text']`)
                    ? 'filled'
                    : ''
                "
                >Descrição da política</label
              >

              <vue-editor
                v-model="policy.text"
                :editor-toolbar="customToolbar"
                placeholder="Digite o texto aqui"
              ></vue-editor>

              <!-- <div v-html="policy.text"></div> -->
              <!-- <Editor v-model="policy.text" /> -->

              <a-input
                class="travel-textarea"
                placeholder="Descrição da política"
                style="display: none !important"
                type="textarea"
                v-decorator="[
                  `reservation_policies[${index}]['text']`,
                  {
                    initialValue: formatText(policy),
                    //validateTrigger: ['change', 'blur'],
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
              </a-input>
            </a-form-item>

            <a-form-item class="show-policy">
              <a-switch
                size="small"
                :default-checked="policy.showInContract"
                v-decorator="[
                  `reservation_policies[${index}]['showInContract']`,
                  {
                    initialValue: policy.showInContract,
                  },
                ]"
              />
              <font class="label">Marque para exibir no contrato</font>
            </a-form-item>
          </a-col>
          <a-col :span="2">
            <a-button
              size="small"
              type="danger"
              shape="circle"
              icon="delete"
              style="margin-top: 8px"
              @click="removeRow(index)"
            >
            </a-button>
          </a-col>
        </a-row>

        <a-row class="mb-40 mt-20">
          <a-col :span="24">
            <center>
              <a-button
                @click="addRow(reservation_policies.length)"
                type="primary"
              >
                Adicionar política
              </a-button>
            </center>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "ServicePersonForm",
  components: {
    VueEditor,
  },
  props: {
    contract: Object,
    form: Object,
    reservation_policies: Array,
  },
  data() {
    return {
      policyContent: "",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },
  methods: {
    removeRow(index) {
      this.$emit("removePolicyRow", index);
    },
    addRow(index) {
      this.$emit("updatePolicyRow", index);
    },
    formatText(p) {
      let paragraph = p.text;

      if (p.room != undefined) {
        paragraph = `${p.product} - Quarto ${p.room.number} : ${p.room.name}

${p.text}`;
      }
      return paragraph;
    },
  },
};
</script>
