var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-10"},[_c('a-row',{attrs:{"span":24,"offset":0}},[_c('a-col',{attrs:{"span":24}},[_vm._v("\n      "+_vm._s(_vm.reservation_policies)+"\n      "),_vm._l((_vm.reservation_policies),function(policy,index){return _c('a-row',{key:policy,staticClass:"policy",attrs:{"type":"flex","justify":"center","gutter":[20, 0]}},[_c('a-col',{attrs:{"span":22}},[_c('a-form-item',{staticClass:"travel-input"},[_c('label',{class:_vm.form.getFieldValue(`reservation_policies[${index}]['title']`)
                  ? 'filled'
                  : ''},[_vm._v("Título da política")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `reservation_policies[${index}]['title']`,
                {
                  initialValue: policy.title,
                  validateTrigger: ['blur'],
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `reservation_policies[${index}]['title']`,\n                {\n                  initialValue: policy.title,\n                  validateTrigger: ['blur'],\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Título da política"}})],1),_vm._v(" "),_c('a-form-item',[_c('label',{class:_vm.form.getFieldValue(`reservation_policies[${index}]['text']`)
                  ? 'filled'
                  : ''},[_vm._v("Descrição da política")]),_vm._v(" "),_c('vue-editor',{attrs:{"editor-toolbar":_vm.customToolbar,"placeholder":"Digite o texto aqui"},model:{value:(policy.text),callback:function ($$v) {_vm.$set(policy, "text", $$v)},expression:"policy.text"}}),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `reservation_policies[${index}]['text']`,
                {
                  initialValue: _vm.formatText(policy),
                  //validateTrigger: ['change', 'blur'],
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `reservation_policies[${index}]['text']`,\n                {\n                  initialValue: formatText(policy),\n                  //validateTrigger: ['change', 'blur'],\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"travel-textarea",staticStyle:{"display":"none !important"},attrs:{"placeholder":"Descrição da política","type":"textarea"}})],1),_vm._v(" "),_c('a-form-item',{staticClass:"show-policy"},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `reservation_policies[${index}]['showInContract']`,
                {
                  initialValue: policy.showInContract,
                },
              ]),expression:"[\n                `reservation_policies[${index}]['showInContract']`,\n                {\n                  initialValue: policy.showInContract,\n                },\n              ]"}],attrs:{"size":"small","default-checked":policy.showInContract}}),_vm._v(" "),_c('font',{staticClass:"label"},[_vm._v("Marque para exibir no contrato")])],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":2}},[_c('a-button',{staticStyle:{"margin-top":"8px"},attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.removeRow(index)}}})],1)],1)}),_vm._v(" "),_c('a-row',{staticClass:"mb-40 mt-20"},[_c('a-col',{attrs:{"span":24}},[_c('center',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.addRow(_vm.reservation_policies.length)}}},[_vm._v("\n              Adicionar política\n            ")])],1)],1)],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }